// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import PageHeader from "components/PageHeader/index";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Rental page sections
import Places from "pages/Menu/Rental/sections/Places";
import Faq from "pages/Menu/Rental/sections/Faq";
import Contact from "pages/Menu/Coworking/sections/Contact";

// Routes
import footerRoutes from "footer.routes";

function Menu() {
  return (
    <>
      <PageHeader img="menu" />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
          zIndex: "20",
        }}
      >
        <Places />
        <Faq />
        <Contact />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Menu;
