// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/jts.webp";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "JT's Manukan Grille",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "#",
    },
    {
      icon: <TwitterIcon />,
      link: "#",
    },
    {
      icon: <GitHubIcon />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        {
          name: "about us",

          href: "#",
        },
        // {
        //   name: "freebies",

        //   href: "https://www.creative-tim.com/templates/free",
        // },
        // {
        //   name: "premium tools",

        //   href: "https://www.creative-tim.com/templates/premium",
        // },
        {
          name: "blog",

          href: "#",
        },
      ],
    },
    {
      name: "resources",
      items: [
        {
          name: "illustrations",

          href: "#",
        },
        {
          name: "bits & snippets",

          href: "#",
        },
        {
          name: "affiliate program",

          href: "#",
        },
      ],
    },
    {
      name: "help & support",
      items: [
        {
          name: "contact us",

          href: "#",
        },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "#" },
        { name: "privacy policy", href: "#" },
        { name: "licenses (EULA)", href: "#" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" fontSize="0.5rem">
      All rights reserved. Copyright &copy; {date} JT&apos;s Manukan Grille
    </MKTypography>
  ),
};
