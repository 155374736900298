import { useRef, useState, useEffect } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import masaya from "assets/images/masaya.webp";
import masarap from "assets/images/m2.webp";
import malinis from "assets/images/m1.webp";
import mura from "assets/images/m3.webp";

function Steps() {
  const [loading, setLoading] = useState(true);
  const [slides, setSlides] = useState([
    {
      image: masarap,
      label: "The Filipino term for Delicious",
      title: "Masarap",
      description:
        "JT’s offers a selection of Ilonggo dishes specifically from Bacolod City in Negros Occidental. Our specialty is the inasal—a deeply marinated chicken that’s grilled to perfection. We cook it carefully, consistently, ensuring each piece is well-done, while remaining crisp on the outside but tender and juicy on the inside. One bite and you'll be sure to find yourself in the City of Smiles!",
    },
    {
      image: mura,
      label: "The Filipino term for Affordable",
      title: "Mura",
      description:
        "JT’s welcomes everyone! With meals that start at Php 150, the family or barkada can surely enjoy quality, sumptuous food that’s light on the pocket. Where else would you be? Kaon ta!",
    },
    {
      image: malinis,
      label: "The Filipino term for Clean",
      title: "Malinis",
      description:
        "JT’s is committed to serving flavorful dishes that are also clean and safe to consume. That’s why we only apply the best food safety standards, as well as prepare them with utmost care using fresh ingredients from local suppliers. It’s a recipe for a delicious dining experience!",
    },
    {
      image: masaya,
      label: "The Filipino term for Happy",
      title: "Masaya",
      description:
        "JT’s is a staple for many memorable occasions—from family events or dinner with friends to romantic dates. Our branches cook up good vibrations where delicious food and conversations are enjoyed. This also allows us to treat tourists to an Ilonggo dining experience without traveling to Negros Province! It’s just really our happy place that we also share with you.",
    },
  ]);

  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const steps = [
    {
      number: "01",
      label: "Masarap",
    },
    {
      number: "02",
      label: "Mura",
    },
    {
      number: "03",
      label: "Malinis",
    },
    {
      number: "04",
      label: "Masaya",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  let mLay;

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/four-ms/?populate=%2A&pagination[limit]=100`
    );

    const data = await response.json();
    const details = await data.data;

    if (details) {
      setSlides(details);
    }

    setLoading(false);
  }, [loading]);

  if (!loading) {
    mLay = slides.map((s) => (
      <SwiperSlide key={s.id}>
        <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
          <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
            <MKBox p={2}>
              <MKBox
                component="img"
                src={s.attributes.image.data.attributes.url}
                alt={s.attributes.title}
                width="100%"
                borderRadius="xl"
                maxHeight="37.5rem"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
            <MKTypography
              component="h6"
              variant="button"
              opacity={0.7}
              textTransform="uppercase"
              fontWeight="bold"
              color="error"
            >
              {s.attributes.label}
            </MKTypography>
            <MKTypography
              variant="h2"
              fontWeight="bold"
              color="warning"
              sx={{
                fontSize: ({ typography: { d4, d5 } }) => ({
                  xs: d5.fontSize,
                  lg: d4.fontSize,
                }),
              }}
            >
              {s.attributes.title}
            </MKTypography>
            <MKTypography variant="body1" my={1} fontSize={{ xs: "0.75rem", md: "0.8rem" }}>
              {s.attributes.description}
            </MKTypography>
          </Grid>
        </Grid>
      </SwiperSlide>
    ));
  }

  return (
    <MKBox component="section" pb={12} position="relative">
      <Container>
        <MKTypography variant="h3" align="center" fontWeight="bold" gutterBottom sx={{ pb: 5 }}>
          The 4M&apos;s We live by
        </MKTypography>
        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {mLay}

          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "5%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography variant="h2" ref={navigationNextRef} color="dark" sx={navigationStyles}>
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
        <Grid
          container
          mt={16}
          sx={{
            display: "none",
          }}
        >
          {steps.map(({ number, label }, index) => (
            <Grid key={label} item xs={6} lg={3} textAlign="center">
              <MKTypography
                variant="body1"
                color="error"
                fontWeight="bold"
                pl={2}
                textGradient
                sx={{ fontFamily: ({ typography: { h1 } }) => h1.fontFamily, cursor: "pointer" }}
                onClick={() => slideTo(index + 1)}
              >
                <MKBox component="span">{number}</MKBox>
                <MKBox component="span" ml={1}>
                  {label}
                </MKBox>
              </MKTypography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Steps;
