// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function MenuCard({ name, loc, region }) {
  return (
    <Link to={`/branches/${loc}/${region}`}>
      <Card sx={{ mt: 3, backgroundColor: "#1d1d1d" }}>
        <Grid container>
          <Grid item xs={12} sx={{ my: "auto" }}>
            <MKBox py={2}>
              <MKTypography
                variant="h5"
                fontSize={{ xs: "caption.fontSize", md: "h5.fontSize" }}
                textAlign="center"
                sx={{
                  color: "#FDC20E",
                }}
              >
                {name}
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
}

// Typechecking props for the MenuCard
MenuCard.propTypes = {
  loc: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default MenuCard;
