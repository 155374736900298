import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard/OutletsNav";

// Images

import chicken from "assets/images/pecho.webp";

function ItemOutlets() {
  const [outletsData, setOutletsData] = useState([]);
  const params = useParams();

  const manilaDb = [];
  const provinceDb = [];

  useEffect(() => {
    if (params.province === "provincial") {
      setOutletsData(provinceDb);
    } else {
      setOutletsData(manilaDb);
    }
  }, []);

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      <Container>
        <Grid
          justifyContent="center"
          sx={{
            display: "flex",
            overflow: { xs: "scroll", lg: "hidden" },
          }}
        >
          <Grid
            item
            xs={5}
            md={3}
            px={1}
            sx={{
              flexShrink: 0,
            }}
          >
            <MKBox mb={1}>
              <Link to={`/branches/${params.province}`}>
                <Card sx={{ mt: 3, backgroundColor: "#1d1d1d" }}>
                  <Grid container>
                    <Grid item xs={12} sx={{ my: "auto" }}>
                      <MKBox py={2}>
                        <MKTypography
                          variant="h5"
                          fontSize={{ xs: "caption.fontSize", md: "h5.fontSize" }}
                          textAlign="center"
                          sx={{
                            color: "#FDC20E",
                          }}
                        >
                          All Branches{" "}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </MKBox>
          </Grid>
          {outletsData.map((out) => (
            <Grid
              item
              key={out.id}
              xs={5}
              md={3}
              px={1}
              sx={{
                flexShrink: 0,
              }}
            >
              <MKBox mb={1}>
                <HorizontalTeamCard
                  image={chicken}
                  name={out.title}
                  loc={out.loc}
                  region={out.id}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ItemOutlets;
