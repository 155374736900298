import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import founder from "assets/images/catering_jt.webp";

function Testimonials() {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(founder);
  const [message, setMessage] = useState(
    "To our dear valued customers, we wish to thank you for patronizing our restaurant. JT's Manukan would not exist for two decades without your constant support. This serves as an inspiration for us to continue serving you our consistent, comfortable, and value-for-money food. There is a saying that the best compliment for a restaurant are returning customers. We hope JT's would become a heritage restaurant that will be around for generations to come. We are doing our best to achieve customer satisfaction."
  );
  const [name, setName] = useState("Joel Torre");
  const [highlight, setHighlight] = useState("Cheers to more years.");
  const [closingLine, setClosingLine] = useState(
    "Again, thank you for this symbiotic relationship from both ends."
  );

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/founder-message?populate=%2A&pagination[limit]=100`
    );

    const data = await response.json();
    const details = await data.data.attributes;

    if (details) {
      setImage(details.image.data.attributes.url);
      setMessage(details.message);
      setClosingLine(details.closingLine);
      setName(details.name);
      setHighlight(details.highlight);
      setLoading(false);
    }
  }, [loading]);

  return (
    <MKBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={12} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              <Grid item xs={12} md={6} xl={4} px={{ xs: 0, md: 6 }} sx={{ position: "relative" }}>
                <MKBox
                  component="img"
                  src={image}
                  alt="Joel Torre"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body1"
                  color="white"
                  fontWeight="regular"
                  mb={2}
                  fontSize={{ xs: "0.6rem", md: "0.8rem" }}
                  style={{
                    fontFamily: "youth",
                  }}
                >
                  {message}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="white"
                  fontWeight="regular"
                  mb={2}
                  fontSize={{ xs: "0.75rem", md: "0.8rem" }}
                  style={{
                    fontFamily: "youth",
                  }}
                >
                  {closingLine}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="white"
                  fontWeight="regular"
                  mb={2}
                  fontSize={{ xs: "0.75rem", md: "0.8rem" }}
                  style={{
                    fontFamily: "youth",
                  }}
                >
                  <em
                    style={{
                      color: "#FDC20E",
                    }}
                  >
                    {highlight}
                  </em>
                </MKTypography>
                <Icon fontSize="large">format_quote</Icon> <br />
                <MKTypography
                  variant="button"
                  fontWeight="bold"
                  color="warning"
                  sx={{
                    fontFamily: "youth",
                    fontWeight: "bold",
                  }}
                >
                  {name}
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
