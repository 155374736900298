// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function MenuCard({ image, name, loc }) {
  return (
    <Link to={`/branches/${loc}`}>
      <Card sx={{ mt: 3, backgroundColor: "#1d1d1d" }}>
        <Grid container>
          <Grid item xs={12} lg={6} sx={{ mt: -6 }}>
            <MKBox width="100%" pt={2} pb={1} px={2}>
              <MKBox
                component="img"
                src={image}
                alt={name}
                width="100%"
                borderRadius="md"
                shadow="lg"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ my: "auto" }}>
            <MKBox
              pt={{ xs: 1, lg: 2.5 }}
              pb={2.5}
              pr={{ xs: 0, lg: 4 }}
              pl={{ xs: 0, lg: 1 }}
              lineHeight={1}
            >
              <MKTypography
                variant="h5"
                fontSize={{ xs: "caption.fontSize", md: "h5.fontSize" }}
                textAlign={{ xs: "center", lg: "left" }}
                sx={{
                  color: "#FDC20E",
                }}
              >
                {name}
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
}

// Typechecking props for the MenuCard
MenuCard.propTypes = {
  image: PropTypes.string.isRequired,
  loc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default MenuCard;
