import { useEffect, useRef, useState } from "react";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import PageHeader from "components/PageHeader/index";

// Material Kit 2 PRO React examples
// import BottomNav from "examples/Navbars/BottomNav";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Menu from "pages/Presentation/sections/Menu";
import Founder from "pages/Menu/Coworking/sections/Founder";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
// import routes from "routes";
import footerRoutes from "footer.routes";

// Images

function Presentation() {
  const [textData, setTextData] = useState([]);
  const [loading, setLoading] = useState(true);

  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(async () => {
    const response = await fetch(`https://seashell-app-jw7mf.ondigitalocean.app/api/banner-text`);
    const data = await response.json();
    const details = await data.data.attributes;

    const textArray = [];
    textArray.push(details.first);
    textArray.push(details.second);
    textArray.push(details.third);
    textArray.push(details.fourth);
    textArray.push(details.fifth);
    textArray.push(details.sixth);

    setTextData(textArray);

    const typedJS = new Typed(typedJSRef.current, {
      strings: textData,
      typeSpeed: 70,
      backSpeed: 80,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    if (loading) {
      typedJS.destroy();
    }

    setLoading(false);

    return () => typedJS.destroy();
  }, [loading]);

  return (
    <>
      <PageHeader img="landing" />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: 30,
        }}
      >
        <Container
          sx={{
            zIndex: 20,
          }}
        >
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="error"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span ref={typedJSRef} />
            </MKTypography>
          </Grid>
        </Container>
        <Counters />

        <Founder />

        <Menu />
        <Posts />
        <Support />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
