// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={{ lg: 6 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            // xs={12}
            // md={4}
            // sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack direction="row" justifyContent="center" spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="outdoor_grill"
                title="Masarap"
                description="We cook our food with care, well done, not rare. A bite will instantly transport you to the City of Smiles."
              />
              <SimpleInfoCard
                icon="wash"
                title="Malinis"
                description="We stand by our commitment to serve quality food at affordable prices. Our dishes are prepared with fresh ingredients, mostly sourced from local businesses."
              />
              <SimpleInfoCard
                icon="money"
                title="Mura"
                description="We cater to everyone and anyone! Meals start at &#8369;150.00, definitely for the whole family and barkada."
              />
              <SimpleInfoCard
                icon="sentiment_satisfied"
                title="Masaya"
                description="We also cater to tourists who are eager to get the Ilonggo dining experience without having to travel all the way to the province of Negros."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
