import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard/outlets";

// Images
import jtLand from "assets/images/JTs-Web-Banner.webp";

function OutletsGallery() {
  const [outletsData, setOutletsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const actionProps = {
    type: "external",
    route: "#",
    color: "error",
    label: "View",
  };
  const params = useParams();

  const outletsLay = outletsData.map((o) => {
    if (String(params.id) === String(o.attributes.branch.data.id)) {
      // console.log(o);

      return (
        <Grid item xs={12} md={6} lg={4} key={o.id}>
          <MKBox mt={3}>
            <SimpleBookingCard
              image={
                !o.attributes.src.data.attributes.url
                  ? jtLand
                  : `${o.attributes.src.data.attributes.url}`
              }
              title={o.attributes.name}
              action={actionProps}
              c1={o.attributes.c1}
              c2={o.attributes.c2}
              c3={o.attributes.c3}
              wz={o.attributes.wz}
              gm={o.attributes.gm}
              fb={o.attributes.fb}
              ig={o.attributes.ig}
              grab={o.attributes.grab}
              fpanda={o.attributes.foodpanda}
              pick={o.attributes.pick}
            />
          </MKBox>
        </Grid>
      );
    }
    return false;
  });

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/outlets?populate=%2A&pagination[limit]=100`
    );
    const data = await response.json();
    const outletDetails = data.data;
    setOutletsData(outletDetails);
    setLoading(false);
  }, [loading]);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {outletsLay}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default OutletsGallery;
