// @mui material components
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import image from "assets/images/slide3.webp";

function Contact() {
  return (
    <MKBox component="section" pt={{ xs: 0, lg: 12 }} pb={{ xs: 0, lg: 4 }}>
      <MKBox bgColor="error" borderRadius="lg" py={12} px={{ xs: 3, lg: 0 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            xl={6}
            ml="auto"
            sx={{
              "@media screen and (max-width: 992px)": {
                justifyContent: "center",
                textAlign: "center",
              },
            }}
          >
            <MKTypography color="warning" variant="h4" mb={1}>
              Got an upcoming event? celebrate with JT&apos;s!
            </MKTypography>
            <MKTypography color="white" variant="body2" mb={3}>
              Sharing great food made easier! We offer catering services for any of your events.
              Safe to say, it&apos;s always a good idea to have some JT&apos;s goodness! Send us a
              message for inquiries. We&apos;ve got you covered!
            </MKTypography>
            <Grid
              container
              spacing={2}
              sx={{
                "@media screen and (max-width: 992px)": {
                  justifyContent: "center",
                },
              }}
            >
              <Grid item xs={12} lg={1} />
              <Grid item xs={12} sm={8}>
                <MKButton
                  component={Link}
                  to="/catering"
                  variant="gradient"
                  size="large"
                  color="warning"
                  fullWidth
                  sx={{
                    color: "#1d1d1d",
                  }}
                >
                  {" "}
                  Go to Catering Page
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} position="relative">
            <MKBox
              component="img"
              src={image}
              alt="image"
              maxWidth="18.75rem"
              width="100%"
              borderRadius="lg"
              shadow="xl"
              mt={-24}
              display={{ xs: "none", lg: "block" }}
            />
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default Contact;
