import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  const [loading, setLoading] = useState(true);
  const [countleft, setCountLeft] = useState(50);
  const [countright, setCountRight] = useState(25);
  const [nameleft, setNameLeft] = useState("Branches Nationwide");
  const [titleRight, setTitleRight] = useState("Years of Experience");
  const [descriptionLeft, setDescriptionLeft] = useState(
    "Over the years, JT's Manukan Grille has been working hard towards growth and improvement. What started as a takeout counter, we now have over 35+ branches nationwide sharing and spreading the best tasting inasal."
  );
  const [descriptionRight, setDescriptionRight] = useState(
    "For almost two decades, JT’s Manukan Grille has been serving grilled chicken items marinated the Illongo way. “JT’s”, as what most patrons would have it, is the brainchild of actor Joel Torre and his wife, Cristy."
  );

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/landing-counter-1?populate=%2A&pagination[limit]=100`
      // `http://localhost:1337/api/landing-counter-1?populate=%2A&pagination[limit]=100`
    );

    const data = await response.json();
    const details = await data.data.attributes;
    setCountLeft(Number(details.countleft));
    setCountRight(Number(details.countright));
    setNameLeft(details.nameleft);
    setTitleRight(details.titleright);
    setDescriptionLeft(details.descriptionleft);
    setDescriptionRight(details.descriptionright);
    setLoading(false);
  }, [loading]);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              count={countleft}
              suffix="+"
              title={nameleft}
              description={descriptionLeft}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={countright}
              suffix="+"
              title={titleRight}
              description={descriptionRight}
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
