import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/Menu/Rental/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={{ xs: 4, lg: 12 }}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography
              variant="h2"
              align="center"
              fontWeight="bold"
              color="error"
              gutterBottom
              sx={{
                mb: 2,
              }}
            >
              Frequently Asked Questions
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How do I order?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Check out our branch list to know which store is nearest you. You may call or text the
              numbers provided. We are also available in Grab Food and Food Panda.
            </FaqCollapse>
            <FaqCollapse
              title="How can i make the payment?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              You may pay in cash, Gcash or bank transfer. Please ask the branch your order is
              coming from on which payment method they have and/or prefer.
            </FaqCollapse>
            <FaqCollapse
              title="How much time does it take to receive the order?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Our cooking and preparation time is 15-25 minutes. Depending on the distance from our
              store to your drop-off point it usually takes 30-45minutes total from the time
              ordered.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
