import { useState } from "react";
import Swal from "sweetalert2";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/cateringForm.webp";

function Franchise() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const sendInquiry = () => {
    if (name !== "" && email !== "" && reason !== "" && message !== "") {
      fetch(`https://jts-sendmail.onrender.com/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jtsEmail: "catering@jtsmanukangrille.com",
          // jtsEmail: "quimpo.charles@gmail.com",
          name,
          email,
          reason,
          message,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            Swal.fire({
              title: "Inquiry sent!",
              text: "A member of JT's Manukan Grille Family will reach out to you",
              icon: "success",
              confirmButtonText: "Go Back",
            }).then(() => {
              window.location.assign("/");
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      Swal.fire({
        title: "Failed to send the inquiry",
        text: "Please fill out all fields",
        icon: "error",
        confirmButtonText: "Go Back",
      });
    }
  };

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };
  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
  };
  const reasonChangeHandler = (e) => {
    setReason(e.target.value);
  };
  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container>
          <Grid container item px={{ xs: 0, md: "6" }}>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                  <MKBox component="form" p={2} method="post">
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography
                        variant="h2"
                        color="warning"
                        fontSize={{ xs: "h4.fontSize", md: "h2.fontSize" }}
                        mb={1}
                      >
                        Catering Inquiry
                      </MKTypography>
                      <MKTypography variant="body2" color="text" mb={2} fontSize="caption.fontSize">
                        Sharing great food made easier! We offer catering services for any of your
                        events. Safe to say, it&apos;s always a good idea to have some JT&apos;s
                        goodness! Send us a message for inquiries. We&apos;ve got you covered!{" "}
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label="My Name is"
                            placeholder="Full Name"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={name}
                            onChange={nameChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label="My Email is"
                            placeholder="joserzal@gmail.com"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={email}
                            onChange={emailChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label="Type of Event"
                            placeholder="Birthday, Corporate, etc."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={reason}
                            onChange={reasonChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label="Your message"
                            placeholder="Your inquiry for the JT's Manukan Grille Catering Team"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                            value={message}
                            onChange={messageChangeHandler}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton
                          variant="gradient"
                          size="large"
                          color="warning"
                          fullWidth
                          sx={{
                            color: "#1d1d1d",
                          }}
                          onClick={sendInquiry}
                        >
                          Send Message
                        </MKButton>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid
                  item
                  display={{ xs: "none", lg: "block" }}
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Say hi to team JT&apos;s
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Fill up the form and our Communications Team will get back to you within 24
                        hours.
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          (+63)2 8253 1990
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          (+63)2 8253 7532
                        </MKTypography>
                      </MKBox>
                      {/* <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          franchise@jtsmanukangrille.com
                        </MKTypography>
                      </MKBox> */}
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          catering@jtsmanukangrille.com
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          inquire@jtsmanukangrille.com
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Support />
    </>
  );
}

export default Franchise;
