// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard/MenuCard";

// Images

import chicken from "assets/images/pecho.webp";
import pork from "assets/images/lmpo.webp";
import bangus from "assets/images/bngus.webp";
import all from "assets/images/all.webp";

function ItemMenu() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={3}>
            <MKBox mb={1}>
              <HorizontalTeamCard image={chicken} name="Chicken" />
            </MKBox>
          </Grid>
          <Grid item xs={6} lg={3}>
            <MKBox mb={1}>
              <HorizontalTeamCard image={pork} name="Pork" />
            </MKBox>
          </Grid>
          <Grid item xs={6} lg={3}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard image={bangus} name="Seafood" />
            </MKBox>
          </Grid>
          <Grid item xs={6} lg={3}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard image={all} name="view all" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ItemMenu;
