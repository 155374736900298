// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard/branches";

// Images
import lipa from "assets/images/lipa.webp";
import pioneer from "assets/images/jts_alabang.webp";

function Places() {
  const actionProps = {
    type: "internal",
    route: "#",
    color: "error",
    label: "View",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3, justifyContent: "center" }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={pioneer}
                title="Metro Manila"
                action={actionProps}
                loc="metromanila"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={lipa}
                title="Provincial"
                action={actionProps}
                loc="provincial"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
