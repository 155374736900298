// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function DefaultBlogCard({
  image,
  category,
  title,
  description,
  // author,
  raised,
  action,
}) {
  const imageTemplate = (
    <>
      <MKBox
        component="img"
        src={image}
        alt={title}
        borderRadius="lg"
        shadow={raised ? "md" : "none"}
        width="100%"
        height="220px"
        position="relative"
        zIndex={1}
      />
      <MKBox
        borderRadius="lg"
        shadow={raised ? "md" : "none"}
        width="100%"
        height="100%"
        position="absolute"
        left={0}
        top={0}
        sx={
          raised
            ? {
                backgroundImage: `url(${image})`,
                transform: "scale(0.94)",
                filter: "blur(12px)",
                backgroundSize: "cover",
              }
            : {}
        }
      />
    </>
  );

  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={raised ? -3 : 2}>
        {action.type === "internal" ? (
          <Link to={action.route}>{imageTemplate}</Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            {imageTemplate}
          </MuiLink>
        )}
      </MKBox>
      <MKBox
        p={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          "@media screen and (min-width: 992px)": {
            minHeight: "375px",
          },
        }}
      >
        {category && (
          <MKTypography
            variant="caption"
            color={category.color}
            textTransform="uppercase"
            fontWeight="medium"
            textGradient
            sx={{ display: "block" }}
          >
            {category.label}
          </MKTypography>
        )}
        {action.type === "internal" ? (
          <Link to={action.route}>
            <MKTypography
              variant="h5"
              textTransform="capitalize"
              my={1}
              sx={{ display: "inline-block", fontFamily: "youth" }}
            >
              {title}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            <MKTypography
              variant="h5"
              textTransform="capitalize"
              mt={2}
              mb={1}
              sx={{ display: "inline-block" }}
            >
              {title}
            </MKTypography>
          </MuiLink>
        )}
        <MKTypography
          variant="body2"
          component="p"
          color="text"
          fontSize={{ xs: "0.75rem", md: "0.8rem" }}
          sx={{
            flexGrow: 1,
          }}
        >
          {description}
        </MKTypography>

        <MKBox display="flex" alignItems="center" mt={3}>
          <Link
            to={action.route}
            style={{
              width: "100%",
            }}
          >
            <MKButton
              variant="gradient"
              color="error"
              sx={{
                width: "100%",
                fontFamily: "youth",
              }}
            >
              {category.label}
            </MKButton>
          </Link>
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Setting default props for the DefaultBlogCard
DefaultBlogCard.defaultProps = {
  category: false,
  //   author: false,
  raised: true,
};

// Typechecking props for the DefaultBlogCard
DefaultBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.shape({
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  //   author: PropTypes.oneOfType([
  //     PropTypes.shape({
  //       image: PropTypes.string.isRequired,
  //       name: PropTypes.string.isRequired,
  //       date: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  //     }),
  //     PropTypes.bool,
  //   ]),
  raised: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultBlogCard;
