// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information({ label, title, description, description2, description3 }) {
  return (
    <MKBox component="section" py={4} mt={2}>
      <Container>
        <Grid container item xs={12} lg={8} mx="auto">
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: 0,
              paddingTop: 0,
            }}
          >
            <MKTypography
              component="h6"
              variant="button"
              opacity={0.7}
              textTransform="uppercase"
              fontWeight="bold"
              color="error"
            >
              {label}
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            <MKTypography variant="h3" mb={3}>
              {title}
            </MKTypography>
          </Grid>
          <MKTypography variant="body2">{description}</MKTypography>
          <MKTypography
            variant="body2"
            sx={{
              mt: 1,
            }}
          >
            {description2}
          </MKTypography>
          <MKTypography
            variant="body2"
            sx={{
              mt: 1,
            }}
          >
            {description3}
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Setting default values for the props of MKAlert
Information.defaultProps = {
  description2: "",
  description3: "",
};

// Typechecking props of the Information
Information.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string,
  description3: PropTypes.string,
};

export default Information;
