import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// branch card component
import BranchCard from "./BranchCard";

function BranchGallery() {
  const [branchData, setBranchData] = useState([]);
  const [loading, setLoading] = useState(true);

  const params = useParams();

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/branches?populate=%2A&pagination[limit]=100`
    );
    const data = await response.json();
    const branchDetails = data.data;
    setBranchData(branchDetails);
    setLoading(false);
  }, [loading]);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {branchData.map((m) => {
            if (params.province === m.attributes.category.data.attributes.category) {
              return <BranchCard key={m.id} branchArray={m} />;
            }
            return false;
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BranchGallery;
