// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard/branches";

// Images
import jtLand from "assets/images/JTs-Web-Banner.webp";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function BranchCard(props) {
  const actionProps = {
    type: "internal",
    route: "#",
    color: "error",
    label: "View",
  };
  const { branchArray } = props;

  return (
    <Grid item xs={12} md={6} lg={4} key={branchArray.id}>
      <MKBox mt={3}>
        <SimpleBookingCard
          image={
            !branchArray.attributes.image.data.attributes.url
              ? jtLand
              : `${branchArray.attributes.image.data.attributes.url}`
          }
          title={branchArray.attributes.title}
          action={actionProps}
          loc={branchArray.attributes.title}
          region={branchArray.id}
          province={branchArray.id}
        />
      </MKBox>
    </Grid>
  );
}

// Typechecking props for the BranchCard
BranchCard.propTypes = {
  branchArray: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default BranchCard;
