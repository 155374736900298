// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import MuiLink from "@mui/material/Link";

function Support() {
  return (
    <MKBox component="section" pb={{ xs: 0, md: 6 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={6} mb={{ xs: 3, lg: 0 }}>
            <MKTypography variant="h4" mb={0.5}>
              Get in touch with JT&apos;s Manukan Grille
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Get the latest updates and promos when you connect with us
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1.5}
              flexWrap="wrap"
              justifyContent={{ xs: "flex-start", lg: "flex-end" }}
            >
              <MuiLink
                href="https://www.facebook.com/jtsmanukangrille"
                target="_blank"
                rel="noreferrer"
              >
                <MKSocialButton
                  color="facebook"
                  sx={{
                    "@media screen and (max-width: 992px)": {
                      width: "100%",
                    },
                  }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp; jtsmanukangrille
                </MKSocialButton>
              </MuiLink>
              <MuiLink
                href="https://www.instagram.com/jtsmanukan/"
                target="_blank"
                rel="noreferrer"
              >
                <MKSocialButton
                  color="instagram"
                  sx={{
                    "@media screen and (max-width: 992px)": {
                      width: "100%",
                    },
                  }}
                >
                  <MKBox
                    component="i"
                    color="inherit"
                    block
                    mr={0.5}
                    className="fab fa-instagram"
                  />
                  jtsmanukan
                </MKSocialButton>
              </MuiLink>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Support;
