// react-router components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKSocialButton from "components/MKSocialButton";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import grabLogo from "assets/images/grab.webp";
import fpandaLogo from "assets/images/fpanda.webp";
import pickLogo from "assets/images/pick.png";

function SimpleBookingCard({ image, title, c1, c2, c3, wz, gm, fb, ig, grab, pick, fpanda }) {
  return (
    <Card
      sx={{
        backgroundColor: "#FAF2E6",
        minHeight: { xs: "60vh", lg: "500px" },
      }}
    >
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
          height={{ xs: "20vh", lg: "25vh" }}
          sx={{
            objectFit: "cover",
            objectPostion: "center",
          }}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </MKBox>
      <MKBox
        p={3}
        mt={-2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MKTypography variant="h6" color="error" fontWeight="regular" textGradient>
          {title}
        </MKTypography>

        <MKBox>
          <MKTypography variant="overline" component="p" color="dark">
            Contact Number: {c1}
          </MKTypography>
        </MKBox>

        <MKBox>
          <MKTypography
            variant="overline"
            visibility={c2 === "" || !c2 ? "hidden" : "block"}
            component="p"
            color="dark"
          >
            {c2 === "" || !c2 ? `Contact Number: ${c1}` : `Contact Number: ${c2}`}
          </MKTypography>
        </MKBox>

        <MKBox mb={3}>
          <MKTypography
            variant="overline"
            component="p"
            visibility={c3 === "" || !c3 ? "hidden" : "block"}
            color="dark"
          >
            {c3 === "" || !c3 ? `Contact Number: ${c1}` : `Contact Number: ${c3}`}
          </MKTypography>
        </MKBox>

        <MKBox>
          <MKButton
            component={MuiLink}
            href={gm}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            size="small"
            color="error"
            fullWidth
          >
            Open in Google Maps
          </MKButton>

          <MKButton
            component={MuiLink}
            href={wz}
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color="error"
            size="small"
            fullWidth
            sx={{
              marginTop: "10px",
            }}
          >
            Open in Waze{" "}
          </MKButton>
        </MKBox>
      </MKBox>

      <MKBox
        p={3}
        mt={-2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {fb === "" || !fb ? (
          ""
        ) : (
          <MKSocialButton
            sx={{
              marginRight: "10px",
            }}
            component={MuiLink}
            href={fb}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
            &nbsp;Facebook
          </MKSocialButton>
        )}
        {ig === "" || !ig ? (
          ""
        ) : (
          <MKSocialButton component={MuiLink} href={ig} target="_blank" rel="noreferrer">
            <InstagramIcon />
            &nbsp;Instagram
          </MKSocialButton>
        )}
      </MKBox>
      <MKBox
        p={3}
        mt={-2}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!fpanda ? (
          ""
        ) : (
          <MKBox
            sx={{
              width: "100%",
              height: "50px",
              backgroundImage: `url(${fpandaLogo})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
        {!grab ? (
          ""
        ) : (
          <MKBox
            sx={{
              width: "100%",
              height: "50px",
              backgroundImage: `url(${grabLogo})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
        {!pick ? (
          ""
        ) : (
          <MKBox
            sx={{
              width: "100%",
              height: "50px",
              backgroundImage: `url(${pickLogo})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        )}
      </MKBox>
    </Card>
  );
}

// Setting default props for the SimpleBookingCard
SimpleBookingCard.defaultProps = {
  c2: "",
  c3: "",
  pick: false,
  fb: "",
  ig: "",
  grab: false,
  fpanda: false,
};

// Typechecking props for the SimpleBookingCard
SimpleBookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  c1: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  c2: PropTypes.string,
  wz: PropTypes.string.isRequired,
  gm: PropTypes.string.isRequired,
  c3: PropTypes.string,
  fb: PropTypes.string,
  ig: PropTypes.string,
  grab: PropTypes.bool,
  pick: PropTypes.bool,
  fpanda: PropTypes.bool,
};

export default SimpleBookingCard;
