import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import img1 from "assets/images/fndr.webp";
import img2 from "assets/images/jts_alabang.webp";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import Information from "./Information";

function OurEfforts({ label, title, description, description2, description3, img }) {
  const [bgImage, setBgImage] = useState(img1);

  useEffect(() => {
    if (img === "img1") {
      setBgImage(img1);
    }
    if (img === "img2") {
      setBgImage(img2);
    }
  }, []);

  return (
    <MKBox component="section">
      <MKBox
        component="img"
        position="relative"
        sx={{
          width: "100%",
          height: { xs: "15rem", md: "35rem" },
          objectFit: "cover",
          objectPosition: "center 25%",
        }}
        alt="JTs Logo"
        src={bgImage}
      />
      <Information
        label={label}
        title={title}
        description={description}
        description2={description2}
        description3={description3}
      />
    </MKBox>
  );
}

// Setting default values for the props of MKAlert
OurEfforts.defaultProps = {
  description2: "",
  description3: "",
};

// Typechecking props of the OurEfforts
OurEfforts.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description2: PropTypes.string,
  description3: PropTypes.string,
};

export default OurEfforts;
