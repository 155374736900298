// MENU ITEM PAGE

import { useEffect } from "react";

import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import PageHeader from "components/PageHeader/index";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Team from "pages/Company/AboutUs/sections/itemMenu";
import Gallery from "pages/Company/AboutUs/sections/Gallery";

// Routes
import footerRoutes from "footer.routes";

function AboutUs() {
  useEffect(() => {}, []);

  return (
    <>
      <PageHeader img="menu" />

      <Card
        sx={{
          p: 2,
          pt: 0,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: 20,
          backgroundColor: "#FDC20E",
        }}
      >
        <Team />
        <Gallery />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
