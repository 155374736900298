// branch ITEM PAGE

import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import PageHeader from "components/PageHeader/index";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Team from "pages/Company/AboutUs/sections/ItemOutlets";
import Gallery from "pages/Company/AboutUs/sections/OutletsGallery";

// Routes
import footerRoutes from "footer.routes";

function Outlets() {
  return (
    <>
      <PageHeader img="branches" />

      <Card
        sx={{
          p: 2,
          pt: 0,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: 20,
          backgroundColor: "#FDC20E",
        }}
      >
        <Team />
        <Gallery />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Outlets;
