// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard/BranchCard";

// Images

import chicken from "assets/images/jts_alabang.webp";
import pork from "assets/images/lipa.webp";

function ItemBranch() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      <Container>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} md={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard image={chicken} name="Metro Manila" loc="metromanila" />
            </MKBox>
          </Grid>
          <Grid item xs={6} md={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard image={pork} name="Provincial" loc="provincial" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ItemBranch;
