// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard/food";

// Images
import post1 from "assets/images/bacolod.webp";
import post2 from "assets/images/pioneer.webp";
import post3 from "assets/images/cheers.webp";

function Posts() {
  const posts = [
    {
      id: 1,
      title: "Bacolod Specialties",
      description:
        "While product consistency, great teamwork, and meaningful partnerships play essential roles in bringing success and longevity, the recipe remains simple as JT’s looks into the future: it is to serve tasty, succulent, and fresh grilled delights—that simple, timeless, and delicious homage to a rich culture and heritage.",
      image: post1,
      category: "menu",
      route: "/menu",
    },
    {
      id: 2,
      title: "35+ branches nationwide",
      description:
        "Today, JT’s Manukan Grille goal is to maintain 50 stores in the near future. With 20+ years of serving the delectable inasal chicken, the company continues to evolve. It is now gearing towards a more modern look to cater to the younger demographics as evident by the new logo that can be seen in some branches.",
      image: post2,
      category: "branches",
      route: "/branches",
    },
    {
      id: 3,
      title: "Have the JT's experience at your own home",
      description:
        "Sharing great food made easier! We offer catering services for any of your events. Safe to say, it’s always a good idea to have some JT’s goodness! Send us a message for inquiries. We’ve got you covered!",
      image: post3,
      category: "catering",
      route: "/catering",
    },
  ];

  return (
    <MKBox component="section" pb={7}>
      <Container>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              Kaon Ta!
            </MKTypography>
            <MKTypography
              variant="body2"
              color="text"
              px={{ xs: 0, md: 6 }}
              fontSize={{ xs: "0.75rem", md: "0.8rem" }}
              mb={4}
            >
              Feel free to check out our menu and find the nearest branch to you.
            </MKTypography>
          </Grid>
          {posts.map((p) => (
            <Grid key={p.id} item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
              <DefaultBlogCard
                image={p.image}
                category={{ color: "primary", label: p.category }}
                title={p.title}
                description={p.description}
                action={{ type: "internal", route: p.route }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Posts;
