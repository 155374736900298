/**
/** 
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CoworkingPage from "layouts/pages/menu/coworking";
import FranchisingPage from "layouts/pages/menu/coworking/franchising";
import Rental from "layouts/pages/menu/rental";
import Menu from "layouts/pages/menu/index";
import AboutUs from "layouts/pages/company/about-us";
import Pricing from "layouts/pages/company/pricing";
import SingleArticle from "layouts/pages/blogs/single-article";
import VirtualRealityPage from "layouts/pages/extra/virtual-reality";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "menu",
        collapse: [
          {
            name: "catering",
            route: "/catering",
            component: <CoworkingPage />,
          },
          {
            name: "franchising",
            route: "/franchising",
            component: <FranchisingPage />,
          },
          {
            name: "branches",
            route: "/branches",
            component: <Rental />,
          },
          {
            name: "menu",
            route: "/menu",
            component: <Menu />,
          },
          {
            name: "menu item",
            route: "/menu/:id",
            component: <AboutUs />,
          },
        ],
      },
      {
        name: "company",
        collapse: [
          {
            name: "outlets",
            route: "/branches/:province/:id",
            component: <Pricing />,
          },
        ],
      },
      {
        name: "blogs",
        collapse: [
          {
            name: "About",
            route: "/about-us",
            component: <SingleArticle />,
          },
        ],
      },
      {
        name: "branches",
        collapse: [
          {
            name: "branches",
            route: "/branches/:province",
            component: <VirtualRealityPage />,
          },
        ],
      },
    ],
  },
];

export default routes;
