import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard/menu";

// Images
import jtLand from "assets/images/jt_land.webp";

function Gallery() {
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const actionProps = {
    type: "internal",
    route: "#",
    color: "error",
    label: "View",
  };

  const params = useParams();
  const menuData = itemsData.map((m) => {
    if (params.id === m.attributes.food_category.data.attributes.name) {
      const macros = [];
      macros.push(`Serving Size: ${m.attributes.serving}g`);
      macros.push(`Protein: ${m.attributes.protein}g`);
      macros.push(`Calories: ${m.attributes.calories} kcal`);
      macros.push(`Fat: ${m.attributes.fat}g`);
      return (
        <Grid item xs={12} md={6} lg={4} key={m.id}>
          <MKBox mt={3}>
            <SimpleBookingCard
              categories={!m.attributes.protein ? [] : macros}
              image={
                !m.attributes.image.data.attributes.url
                  ? jtLand
                  : `${m.attributes.image.data.attributes.url}`
              }
              title={m.attributes.name}
              action={actionProps}
              description={m.attributes.description}
              price={m.attributes.price}
            />
          </MKBox>
        </Grid>
      );
    }

    return false;
  });

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/items?populate=%2A&pagination[limit]=100`
    );
    const data = await response.json();
    const details = data.data.sort((a, b) => (a.id < b.id ? -1 : Number(a.id > b.id)));
    setItemsData(details);
    setLoading(false);
    // console.log(itemsData);
  }, [loading]);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {menuData}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Gallery;
