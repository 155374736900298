// BRANCHES PAGE

// @mui material components

import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import PageHeader from "components/PageHeader/index";

// Rental page sections
import Places from "pages/Menu/Rental/sections/Branches";
import ContactTwo from "layouts/contact";

// Routes
import footerRoutes from "footer.routes";

// Images

function Rental() {
  return (
    <>
      <PageHeader img="branches" />
      <Card
        sx={{
          p: 2,
          pb: 0,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
          zIndex: "20",
        }}
      >
        <Places />
        <ContactTwo
          formTitle="Franchising Inquiry"
          inquiry="Over the years, JT's Manukan Grille has consistently gained a following, making it to the top of SPOT.PH's list as the No. 1 chicken inasal restaurant since 2015."
        />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Rental;
