// CATERING PAGE

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import PageHeader from "components/PageHeader/index";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Coworking page sections
import Information from "pages/Menu/Coworking/sections/Information";
import ContactTwo from "layouts/contact";

// Routes
import footerRoutes from "footer.routes";

// Images

function Coworking() {
  return (
    <>
      <PageHeader img="catering" />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: "20",
        }}
      >
        <Information />
        <ContactTwo />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Coworking;
