import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// SwiperJS
// import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
// @mui material components

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Images

import bgImage from "assets/images/slide1.webp";
import bgImage2 from "assets/images/slide2.webp";
import bgImage3 from "assets/images/land-2.webp";
import bgImage4 from "assets/images/land-1.webp";
import granada from "assets/images/granada1.webp";
import branch from "assets/images/bacolod.webp";
import menu from "assets/images/menuHead.webp";

import menuImg from "assets/images/jtsBanner.webp";
import mobileHead from "assets/images/JTs-Mobile-Banner.webp";

import { Autoplay, Virtual } from "swiper";

function HeaderTwo({ img }) {
  const [jumbo, setJumbo] = useState(bgImage);
  const [sliderImages, setSliderImages] = useState([bgImage, bgImage2, bgImage3, bgImage4]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/slider-image?populate=%2A&pagination[limit]=100`
    );
    const data = await response.json();
    const details = await data.data.attributes.image.data;
    setSliderImages(details);
    setLoading(false);

    if (img !== "landing") {
      if (matches) {
        setJumbo(mobileHead);
      }
      setJumbo(menuImg);
    }
    if (img === "about") {
      setJumbo(granada);
    }
    if (img === "menu") {
      setJumbo(menu);
    }
    if (img === "branches") {
      setJumbo(branch);
    }
  }, [loading]);

  let sliderGallery;

  if (!loading) {
    sliderGallery = sliderImages.map((sl) => (
      <SwiperSlide key={sl.id}>
        <MKBox
          component="img"
          sx={{
            position: "relative",
            top: 0,
            left: 0,
            zIndex: 10,
            width: "100%",
            height: "100%",
            objectFit: { xs: "contain", md: "cover" },
            objectPosition: "center",
          }}
          alt="JTs Logo"
          src={sl.attributes.url}
          virtualIndex={sl.id}
        />
      </SwiperSlide>
    ));
  }

  if (img === "landing") {
    return (
      <MKBox component="header" height={{ xs: "250px", md: "400px", lg: "700px" }}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Autoplay, Virtual]}
          autoplay={{ delay: 4000 }}
          speed={700}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {sliderGallery}
        </Swiper>
      </MKBox>
    );
  }

  return (
    <>
      <MKBox
        component="header"
        position="relative"
        height={{ xs: "250px", md: "80vh" }}
        width="100%"
      >
        <MKBox
          component="img"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
            width: "100%",
            objectPosition: jumbo === menuImg ? "top" : "center",
            objectFit: "cover",
            backgroundColor: "#faf3e6",
          }}
          alt="JTs Logo"
          src={jumbo}
        />
      </MKBox>
    </>
  );
}

HeaderTwo.propTypes = {
  img: PropTypes.node.isRequired,
};

export default HeaderTwo;
