// react-router-dom components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import jtsLogo from "assets/images/jts_banner.webp";

function DefaultFooter({ content }) {
  const {
    // brand, socials, menus,
    copyright,
  } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid item xs={12} sx={{ textAlign: "center", mb: 5 }}>
          <Grid item xs={12} md={6} sx={{ mx: "auto", my: -4.5 }}>
            <img src={jtsLogo} alt="JT's Manukan Logo" width="75%" />
          </Grid>

          {copyright}
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
