// @mui material components
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

function Places() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const actionProps = {
    type: "internal",
    route: "#",
    color: "error",
    label: "View",
  };

  useEffect(async () => {
    const response = await fetch(
      `https://seashell-app-jw7mf.ondigitalocean.app/api/food-categories?populate=%2A`
    );
    const data = await response.json();
    const details = data.data.sort((a, b) => (a.id < b.id ? -1 : Number(a.id > b.id)));
    setCategories(details);
    setLoading(false);
    // console.log(details);
  }, [loading]);

  const categoriesGallery = categories.map((c) => (
    <Grid item xs={12} md={6} lg={4} key={c.id}>
      <MKBox mt={3}>
        <SimpleBookingCard
          image={`${c.attributes.image.data.attributes.url}`}
          title={c.attributes.name}
          action={actionProps}
        />
      </MKBox>
    </Grid>
  ));

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {categoriesGallery}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
