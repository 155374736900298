import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Link } from "react-router-dom";
import App from "App";
// import Layout from "components/Layout";
import jtsLogo from "assets/images/jts-2.webp";

ReactDOM.render(
  <BrowserRouter>
    <Link to="/" style={{ width: "75%", paddingTop: "0.5%", paddingBottom: "0.5%" }}>
      <img
        src={jtsLogo}
        style={{ height: "50px", zIndex: "9999", position: "fixed", top: 15, left: 10 }}
        alt="JTs Logo"
      />
    </Link>
    {/* <Layout> */}
    <App />
    {/* </Layout> */}
  </BrowserRouter>,
  document.getElementById("root")
);
