// ABOUT US PAGE
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import PageHeader from "components/PageHeader/index";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import BreakPar from "pages/Blogs/SingleArticle/sections/BreakPar";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";

// Routes
import footerRoutes from "footer.routes";

// Images

function SingleArticle() {
  return (
    <>
      <PageHeader img="about" />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          zIndex: "20",
        }}
      >
        <Information
          label="How We Started"
          title="The JT's Manukan Grille Origin Story"
          description2="At the heart of Joel and Cristy’s efforts was the passion to concoct and serve tasty, succulent, and fresh grilled chicken, and it sparked an opportunity for them to finally have their dreams realized."
          description="For two decades and counting, JT’s Manukan Grille has been serving grilled chicken items marinated the Illongo way. “JT’s”, as what most patrons would have it, is the brainchild of actor Joel Torre and his wife, Cristy. It all began when the couple started experimenting with various marinades and recipes passed down from their parents and aunts. And while the desire to share their delicacy with others burned fiercely, turning it into a business was also inevitable."
          description3="In May 2003, Joel was offered a space at one of the properties of the Vera Perezes. Indeed, it was time to test their recipes and the Illongo couple did not disappoint as word quickly got around of how juicy and tender their chicken was. From what was supposed to be a take out counter along Granada Street in Quezon City to becoming a full-fledged restaurant, the original JT’s Manukan Grille was born."
        />
        <BreakPar
          label="The Founder"
          img="img1"
          title="CELEBRITY TURNS RESTAURATEUR"
          description2="For award-winning film actor and TV star Joel Torre, no visit to his hometown Bacolod would be complete without his must-eat inasal meals. The food business was second nature to him, since his mother Luz de Leon-Torre was a pioneer in the catering industry."
          description="Joel and his wife Cristy Azcona-Torre decided to turn entrepreneurs, opening the first JT’s Manukan Grille in 2003. Naturally, they chose Bacolod’s most famous recipe – the chicken inasal – as their signature dish, and they were not mistaken. When US celebrity chef Andrew Zimmern proclaimed Filipino food as the next big thing in 2012, he listed the inasal among the Philippines’ iconic foods."
          description3="Over the years, JT’s Manukan Grille has consistently gained a following in its various locations, making it to the top of SPOT.PH’s list as the No. 1 chicken inasal restaurant since 2015."
        />

        <BreakPar
          label="Our Vision"
          img="img2"
          title="TODAY THE PHILIPPINES, TOMORROW THE WORLD"
          description="Today, JT’s Manukan Grille has 35+ branches nationwide with the goal of reaching 50 stores in the near future. With 20+ years of serving the delectable inasal chicken, the company continues to evolve. It is now gearing towards a more modern and industrial look to cater to the younger demographics as evident by the new logo and signage that can be seen in some branches."
          description2="While product consistency, great teamwork, and meaningful partnerships play essential roles in bringing success and longevity, the recipe remains simple as JT’s looks into the future: it is to serve tasty, succulent, and fresh grilled delights—that simple, timeless, and delicious homage to a rich culture and heritage."
        />

        <Posts />
        <Support />
      </Card>
      <MKBox px={1}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SingleArticle;
