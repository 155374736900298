import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

import DiningIcon from "@mui/icons-material/Dining";
import StorefrontIcon from "@mui/icons-material/Storefront";
// import HomeIcon from "@mui/icons-material/Home";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import FranchiseIcon from "@mui/icons-material/AppRegistration";
import CoPresentIcon from "@mui/icons-material/CoPresent";

import { Link } from "react-router-dom";

const navItems = [
  {
    id: 2,
    title: "Menu",
    to: "/menu",
    icon: <DiningIcon fontSize="medium" />,
    type: "relative",
  },
  {
    id: 3,
    title: "Branches",
    to: "/branches/metromanila",
    icon: <StorefrontIcon fontSize="medium" />,
    type: "relative",
  },
  // {
  //   id: 6,
  //   title: "Franchise",
  //   to: "/franchising",
  //   icon: <FranchiseIcon fontSize="medium" />,
  //   type: "relative",
  // },
  {
    id: 4,
    title: "Catering",
    to: "/catering",
    icon: <LocalShippingIcon fontSize="medium" />,
    type: "relative",
  },
  {
    id: 5,
    title: "About",
    to: "/about-us",
    icon: <CoPresentIcon fontSize="medium" />,
    type: "relative",
  },
  {
    id: 7,
    title: "Order Now",
    to: "https://order.jtsmanukangrille.com",
    icon: <CoPresentIcon fontSize="medium" />,
    type: "absolute",
  },
];

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <Paper
        sx={{
          position: "fixed",
          width: { xs: "100%", md: "50vw" },
          bottom: 10,
          left: "50%",
          transform: "translate(-50%)",
          right: 0,
          paddingY: "10px",
          fontFamily: "youth",
          zIndex: 999,
          bgcolor: "#FAF2E6",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          className="menuItems"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{
            "& .Mui-selected": {
              color: "#D83230",
              "& .MuiSvgIcon-root": {
                color: "#D83230",
              },
            },

            bgcolor: "#FAF2E6",
          }}
        >
          {navItems.map((i) =>
            i.type === "absolute" ? (
              <BottomNavigationAction
                key={i.id}
                label={i.title}
                value={i.title}
                component="a"
                href={i.to}
                // icon={i.icon}
                rel="noopener noreferrer"
                target="_blank"
                sx={{
                  fontFamily: "youth",
                  cursor: "pointer",
                  textAlign: "center",
                  backgroundColor: "#da3032",
                  color: "#f2f2f2 !important",
                  border: "3px solid #040707",
                  borderRadius: "12%",
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#fec113",
                    color: "#040707 !important",
                    border: "3px solid #040707",
                  },
                }}
              />
            ) : (
              <BottomNavigationAction
                key={i.id}
                label={i.title}
                value={i.title}
                component={Link}
                to={i.to}
                icon={i.icon}
                sx={{
                  fontFamily: "youth",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              />
            )
          )}
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
