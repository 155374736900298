import { Fragment } from "react";

// react-router components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// chicken bg

function SimpleBookingCard({ image, title, description, categories, price }) {
  return (
    <Card
      sx={{
        backgroundColor: "#FAF2E6",
        minHeight: { xs: "100%", md: "375px" },
      }}
    >
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </MKBox>
      <MKBox p={3} pb={0} mt={-2}>
        <MKTypography display="inline" variant="h5" fontWeight="bold">
          {title}
        </MKTypography>
        <MKTypography display="block" variant="h6" fontWeight="bold">
          &#8369; {price}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography
            variant="body2"
            component="p"
            color="text"
            fontWeight="bold"
            fontSize="caption.fontSize"
          >
            {description}
          </MKTypography>
          {categories.length > 0 && (
            <MKTypography
              display="block"
              variant="body2"
              color="text"
              fontWeight="bold"
              fontSize="caption.fontSize"
              my={0.75}
            >
              {categories.map((category) => (
                <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
              ))}
            </MKTypography>
          )}
        </MKBox>
      </MKBox>
    </Card>
  );
}

// Setting default props for the SimpleBookingCard
SimpleBookingCard.defaultProps = {
  categories: [],
  description: "",
};

// Typechecking props for the SimpleBookingCard
SimpleBookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBookingCard;
